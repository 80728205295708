<template>
    <section id="propertyservice" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Are you in need to oversee Property Maintenance</h2>
          <p style="padding-top:20px">We take care of complete maintenance on your property</p>
        </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4>{{ obj.header }}</h4>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/PROFESSIONAL.jpg',
        header: 'PROFESSIONAL ASSISTANCE',
        caption: 'We provide professional assistance for all the services under maintenance. It includes general cleaning to undertaking regular repairs and services.'
      },
      {
        path: 'services/DAY-SERVICES.jpg',
        header: 'DAY PORTER SERVICES',
        caption: 'We have the finest group of dedicated day porters who work day in their shifts timings to keep the premises clean and organized.'
      },
      {
        path: 'services/Maintenance.jpg',
        header: 'EFFICIENT OPERATION',
        caption: 'Our maintenance team works efficiently and skilfully to ensure the place is well maintained. We work as a team to ensure successful operation.'
      }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#propertyservice {
    padding: 50px 30px 10px 30px;
}
.services .icon-box i {
  float: left;
  color: #f6b024;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

</style>