<template>
    <section id="parkingicon" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h4>Property Maintenance</h4>
          </div>

        <div class="row" style="padding-top:30px">
          <div class="col-lg-4 col-md-6 col-12 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
              <Image :fpath=" obj.path " class="embedimage"/>
              <h4 class="embservice">{{ obj.header }}</h4>
              <p class="embservice">{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
         path: 'background/PLUMBING.png',
        header: 'PLUMBING',
        caption: 'We undertake plumbing repairs that include faucet repairs, tank maintenance, and electrical equipments functioning.'
      },
      {
         path: 'background/LAWN-MAINTENANCE.png',
        header: 'LAWN MAINTENANCE',
        caption: 'Mowing the grass, pruning and maintaining gardens, preserving the cleanliness of the surroundings of the premises is all a part of lawn maintenance we provide.'
      },
      {
         path: 'background/PAINTING.png',
        header: 'PAINTING',
        caption: 'We white wash and paint the campus when it’s needed. This is to the client’s benefit as you don’t have to look to hire separately to undertake painting.'
      },
      {
         path: 'background/GENERAL-CLEANING.png',
        header: 'GENERAL CLEANING',
        caption: 'General cleaning is mandatory part of maintenance. Our expert group of workers makes sure that your grounds are clean day in and out.'
      },
      {
         path: 'background/SECURITY-SERVICES.png',
        header: 'SECURITY SERVICES',
        caption: 'We provide security services too. A group of dedicated individuals work in shift rotational timings to ensure the premise is secured and all entries and exits are monitored cautiously.'
      },
      {
         path: 'background/CUSTOMER-SERVICES.png',
        header: 'CUSTOMER SERVICES',
        caption: 'Customer’s feedbacks are important for the growth of any company and hence necessary to provide customer services that include customer complaints, feedbacks and tips to improve on the service of the staff in the company.'
      }
    ]

  })
    
}
</script>
<style>
.services .icon-box img {
    float: left;
    margin-top: 10px;
  }
img.embedimage {
    width: 15%;
}
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#parkingicon {
    padding: 50px 30px 10px 30px;
}


.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
    margin-top: 10px;
}
.embservice{
  margin-left: 70px;
}
.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>