<template>
    <Header/>
    <Parkingmainheader/>
    <Propertymainservice/>
    <Parkingmainicons/>
    <Footer/>
</template>

<script>

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Propertymainservice from '@/components/Propertymainservice.vue'
import Parkingmainicons from '@/components/Parkingmainicons.vue'
import Parkingmainheader from '@/components/Parkingmainheader.vue'

export default {
  components: {
     Header,
    Footer,
    Propertymainservice,
    Parkingmainicons,
    Parkingmainheader
  }
}
</script>